import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { StaffDBService } from './@db/staff-db.service';
import { ServerEvents } from './server-events.enum';
import * as Raven from 'raven-js';
var StaffSessionService = /** @class */ (function () {
    function StaffSessionService(api) {
        var _this = this;
        this.api = api;
        this.prefix = 'staffs';
        this.staff = new ReplaySubject(1);
        this.staffPk = this.staff.map(function (s) { return s && s.pk || null; });
        this.googleAvatar = this.staff.map(function (s) { return s && s.picture; });
        this.events = this.staffPk.switchMap(function (pk) { return pk && _this.api.getStaffEvents(pk) || Observable.empty(); });
        // Has logged out from the current session
        this.hasLoggedOut = this.events
            .switchMap(function (e) { return _this.staffPk.map(function (pk) { return [pk, e]; }); })
            .filter(function (_a) {
            var staffPk = _a[0], e = _a[1];
            return e.staff_id === staffPk && e.event === ServerEvents.staffLoggedOut;
        })
            .debounceTime(200)
            .switchMap(function () { return _this.api.getMe(); })
            .filter(function (s) { return !s; })
            .shareReplay(1);
        this.getLoggedInStaff();
        this.staff.subscribe(function (staff) {
            if (staff) {
                Raven.setUserContext({
                    id: staff.pk,
                    username: staff.username || staff.__str__,
                    email: staff.email,
                });
            }
            else {
                Raven.setUserContext();
            }
        });
        // If Staff has logged out, as reported by Server, null this
        this.hasLoggedOut.subscribe(function () { return _this.staff.next(null); });
    }
    StaffSessionService.prototype.getLoggedInStaff = function () {
        var _this = this;
        this.api.getMe().subscribe(function (staff) { return _this.staff.next(staff); });
    };
    StaffSessionService.prototype.isLoggedIn = function () {
        return this.staff.map(function (staffData) { return !!staffData; }).first();
    };
    StaffSessionService.prototype.login = function (username, password) {
        var _this = this;
        return this.api.login(username, password).do(function (staffData) { return _this.staff.next(staffData); });
    };
    StaffSessionService.prototype.loginViaToken = function (token) {
        var _this = this;
        return this.api.loginViaToken(token).do(function (staffData) { return _this.staff.next(staffData); });
    };
    StaffSessionService.prototype.logout = function () {
        var _this = this;
        return this.api.logout().do(function () { return _this.staff.next(null); });
    };
    StaffSessionService.prototype.hasPerm = function (permAlias) {
        return this.staff.map(function (staffData) {
            if (!staffData) {
                return false;
            }
            if (staffData.is_superuser) {
                return true;
            }
            return staffData.permissions
                .filter(function (permission) { return permission.alias === permAlias; })
                .length > 0;
        });
    };
    StaffSessionService.prototype.anyPerm = function (permAliasList) {
        return this.staff.map(function (staffData) {
            if (!staffData) {
                return false;
            }
            if (staffData.is_superuser) {
                return true;
            }
            return permAliasList.filter(function (permAlias) {
                return staffData.permissions.filter(function (permission) { return permission.alias === permAlias; }).length > 0;
            }).length > 0;
        });
    };
    StaffSessionService.prototype.allPerm = function (permAliasList) {
        return this.staff.map(function (staffData) {
            if (!staffData) {
                return false;
            }
            if (staffData.is_superuser) {
                return true;
            }
            return permAliasList.filter(function (permAlias) {
                return staffData.permissions.filter(function (permission) { return permission.alias === permAlias; }).length > 0;
            }).length === permAliasList.length;
        });
    };
    StaffSessionService.prototype.isSuperuser = function () {
        return this.staff.map(function (staff) { return staff && staff.is_superuser || false; });
    };
    return StaffSessionService;
}());
export { StaffSessionService };
