var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { PaginationEvents } from './bb-rest-pagination.interface';
import * as _ from 'lodash';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/startWith';
var BBRestPagination = /** @class */ (function () {
    function BBRestPagination(manager) {
        var _this = this;
        this.manager = manager;
        this.results = new BehaviorSubject([]);
        this.refreshSubject = new Subject();
        this.refreshDebounce = this.refreshSubject.debounceTime(200);
        this.events = new Subject();
        this.busy = new BehaviorSubject(false);
        this._page = 0;
        this._pageLimit = 100;
        this.resultCount = null;
        this.refreshDebounce.subscribe(function () { return _this.getRecords(); });
    }
    Object.defineProperty(BBRestPagination.prototype, "prefix", {
        get: function () {
            return this._prefix;
        },
        set: function (prefix) {
            var oldPrefix = this._prefix;
            this._prefix = prefix;
            if (oldPrefix !== prefix) {
                this._page = 0; // Changing the prefix should go back to page=0
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BBRestPagination.prototype, "query", {
        get: function () {
            return this._query;
        },
        set: function (query) {
            var oldQuery = this._query;
            this._query = query;
            if (!_.isEqual(oldQuery, query)) {
                this._page = 0; // Changing the query should go back to page=0
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BBRestPagination.prototype, "extraParams", {
        get: function () {
            return this._extraParams;
        },
        set: function (extraParams) {
            var oldExtraParams = this._extraParams;
            this._extraParams = extraParams;
            if (!_.isEqual(oldExtraParams, extraParams)) {
                this._page = 0; // Changing the query should go back to page=0
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    BBRestPagination.prototype.getExtraParam = function (key) {
        return this._extraParams[key];
    };
    BBRestPagination.prototype.setExtraParams = function (params) {
        this.extraParams = params;
    };
    BBRestPagination.prototype.updateExtraParams = function (params) {
        var oldExtraParams = this._extraParams;
        this._extraParams = _.assign({}, this._extraParams, params);
        if (!_.isEqual(oldExtraParams, this._extraParams)) {
            this._page = 0; // Changing the query should go back to page=0
            this.refreshSubject.next();
        }
    };
    BBRestPagination.prototype.deleteExtraParam = function () {
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            keys[_i] = arguments[_i];
        }
        for (var key in keys) {
            if (this._extraParams.hasOwnProperty(key)) {
                delete this._extraParams[key];
            }
        }
        this.refreshSubject.next();
    };
    Object.defineProperty(BBRestPagination.prototype, "page", {
        get: function () {
            return this._page;
        },
        set: function (page) {
            var oldPage = this._page;
            this._page = page;
            if (oldPage !== page) {
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BBRestPagination.prototype, "pageLimit", {
        get: function () {
            return this._pageLimit;
        },
        set: function (pageLimit) {
            var oldPageLimit = this._pageLimit;
            this._pageLimit = pageLimit;
            if (oldPageLimit !== pageLimit) {
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BBRestPagination.prototype, "pageCount", {
        get: function () {
            if (this.resultCount) {
                return Math.ceil(this.resultCount / this.pageLimit);
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BBRestPagination.prototype, "orderBy", {
        get: function () {
            return this._orderBy;
        },
        set: function (orderBy) {
            var oldOrderBy = orderBy;
            this._orderBy = orderBy;
            if (oldOrderBy !== orderBy) {
                this.refreshSubject.next();
            }
        },
        enumerable: true,
        configurable: true
    });
    BBRestPagination.prototype.getRecords = function () {
        return __awaiter(this, void 0, void 0, function () {
            var task_id, pageResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // If prefix is not provided, don't bother
                        if (!this.prefix) {
                            return [2 /*return*/];
                        }
                        task_id = this.validTaskID = _.uniqueId();
                        this.events.next(PaginationEvents.refreshStart);
                        this.busy.next(true);
                        // If another refresh supersedes this refresh, abort
                        if (task_id !== this.validTaskID) {
                            this.events.next(PaginationEvents.refreshCancelled);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.manager
                                .getListResult(this.prefix, this.query, this.page + 1, this.orderBy, this.pageLimit, this.extraParams)
                                .toPromise()];
                    case 1:
                        pageResults = _a.sent();
                        // If by this time we are superseded, do not emit results
                        if (task_id !== this.validTaskID) {
                            this.events.next(PaginationEvents.refreshCancelled);
                            return [2 /*return*/];
                        }
                        // Conclude
                        this.resultCount = pageResults.count;
                        this.results.next(pageResults.results);
                        this.events.next(PaginationEvents.refreshEnd);
                        this.busy.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    BBRestPagination.prototype.refresh = function () {
        this.refreshSubject.next();
    };
    BBRestPagination.prototype.setPage = function (options) {
        this.page = options.page || options.pageIndex;
        this.pageLimit = options.pageLimit || options.pageSize;
    };
    return BBRestPagination;
}());
export { BBRestPagination };
