import {Injectable} from '@angular/core';
import {BBRestEndpointService} from '../@modules/bb-rest/bb-rest-endpoint.service';
import {Observable} from 'rxjs/Observable';
import {Staff} from '../@models/staff.model';
import {HttpErrorResponse} from '@angular/common/http';
import {get400OrComplain} from '../@utils/pop-then-str';
import {WS_PREFIX} from '../../const';
import {Login} from '../@models/login.model';

@Injectable()
export class StaffDBService {
  prefix = 'staffs';
  _10minutes = 10 * 60 * 1000;

  loginOptions = this.api.run_list_method('GET', 'staffs', 'login-options').shareReplay(1);
  mayGoogleSignIn = this.loginOptions.map((options: Login.Options) => options.google_sign_in);
  mayUsernamePassword = this.loginOptions.map((options: Login.Options) => options.username_password);

  cache: { [pk: number]: Observable<Staff.Model> } = {};
  private staffEvents: { [pk: number]: Observable<Staff.Event> } = {};

  constructor(private api: BBRestEndpointService) {
  }

  get(staffId: number): Observable<Staff.Model> {
    return this.api.run_detail('GET', this.prefix, staffId);
  }

  getCached(staffId: number): Observable<Staff.Model> {
    if (!this.cache.hasOwnProperty(staffId)) {
      this.cache[staffId] = Observable.of(staffId)
        .switchMap(leadId_ => this.api.run_detail('GET', this.prefix, leadId_))
        .debounceTime(this._10minutes);
    }
    return this.cache[staffId].first();
  }

  create(value: Staff.CreateVars): Observable<Staff.Model> {
    return this.api.run_list('POST', this.prefix, value);
  }

  update(staffPk: number, vars: Staff.UpdateVars): Observable<Staff.Model> {
    return this.api.run_detail('PATCH', this.prefix, staffPk, vars);
  }

  getStaffForBranch(branchPk: number): Observable<Staff.Model> {
    return this.api.getAllRecords(
      this.prefix, ['|', {authorized_branches: branchPk}, {is_superuser: true}]);
  }

  setActive(staffPk: number, isActive: boolean): Observable<Staff.Model> {
    return this.api.run_detail('PATCH', this.prefix, staffPk, {is_active: isActive});
  }

  getMe(): Observable<Staff.Model> {
    return this.api.run_list_method('GET', this.prefix, 'me')
      .catch((error: HttpErrorResponse) => {
        if (error.status === 403) {
          return Observable.of(null);
        } else {
          throw error;
        }
      });
  }

  login(username, password): Observable<Staff.Model> {
    return this.api.run_list_method('POST', this.prefix, 'login', {username: username, password: password})
      .catch((error: HttpErrorResponse) => Observable.throw(get400OrComplain(error)));
  }

  loginViaToken(token: string): Observable<Staff.Model> {
    return this.api.run_list_method('POST', this.prefix, 'login-with-token', {access_token: token})
      .catch((error: HttpErrorResponse) => Observable.throw(get400OrComplain(error)));
  }

  logout(): Observable<boolean> {
    return this.api.run_list_method('POST', this.prefix, 'logout');
  }

  getStaffEvents(staffPk: number): Observable<Staff.Event> {
    if (!this.staffEvents.hasOwnProperty(staffPk)) {
      this.staffEvents[staffPk] = Observable
        .webSocket<Staff.Event>(`${WS_PREFIX}/staffs/${staffPk}/events`)
        .retryWhen(errorSubj => errorSubj.delayWhen(() => Observable.interval(500))).shareReplay(1);
    }
    return this.staffEvents[staffPk];
  }

  setPassword(pk: number, password: string): Observable<Staff.Event> {
    return this.api.run_detail_method('PATCH', this.prefix, pk, 'set-password', {password: password});
  }

  search(keywords: string, limit: number = 12): Observable<Staff.Model> {
    return this.api.getLimitedRecords<Staff.Model>(this.prefix, null, null,
      {search: keywords}, limit);
  }
}
