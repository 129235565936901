import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Staff} from './@models/staff.model';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {StaffDBService} from './@db/staff-db.service';
import {ServerEvents} from './server-events.enum';
import * as Raven from 'raven-js';


@Injectable()
export class StaffSessionService {
  prefix = 'staffs';
  staff = new ReplaySubject<Staff.Model | null>(1);
  staffPk: Observable<number> = this.staff.map(s => s && s.pk || null);
  googleAvatar = this.staff.map((s: Staff.Model) => s && s.picture);

  events = this.staffPk.switchMap(pk => pk && this.api.getStaffEvents(pk) || Observable.empty());

  // Has logged out from the current session
  hasLoggedOut = this.events
    .switchMap(e => this.staffPk.map(pk => <[number, Staff.Event]>[pk, e]))
    .filter(([staffPk, e]) => e.staff_id === staffPk && e.event === ServerEvents.staffLoggedOut)
    .debounceTime(200)
    .switchMap(() => this.api.getMe())
    .filter(s => !s)
    .shareReplay(1);

  constructor(private api: StaffDBService) {
    this.getLoggedInStaff();

    this.staff.subscribe((staff: Staff.Model) => {
      if (staff) {
        Raven.setUserContext({
          id: staff.pk,
          username: staff.username || staff.__str__,
          email: staff.email,
        });
      } else {
        Raven.setUserContext();
      }
    });

    // If Staff has logged out, as reported by Server, null this
    this.hasLoggedOut.subscribe(() => this.staff.next(null));
  }

  getLoggedInStaff() {
    this.api.getMe().subscribe(staff => this.staff.next(staff));
  }

  isLoggedIn(): Observable<boolean> {
    return this.staff.map(staffData => !!staffData).first();
  }

  login(username, password): Observable<Staff.Model> {
    return this.api.login(username, password).do(staffData => this.staff.next(staffData));
  }

  loginViaToken(token: string): Observable<Staff.Model> {
    return this.api.loginViaToken(token).do(staffData => this.staff.next(staffData));
  }

  logout(): Observable<boolean> {
    return this.api.logout().do(() => this.staff.next(null));
  }

  hasPerm(permAlias: string): Observable<boolean> {
    return this.staff.map(staffData => {
      if (!staffData) {
        return false;
      }
      if (staffData.is_superuser) {
        return true;
      }
      return staffData.permissions
        .filter(permission => permission.alias === permAlias)
        .length > 0;
    });
  }

  anyPerm(permAliasList: string[]): Observable<boolean> {
    return this.staff.map(staffData => {
      if (!staffData) {
        return false;
      }
      if (staffData.is_superuser) {
        return true;
      }
      return permAliasList.filter(permAlias =>
        staffData.permissions.filter(
          permission => permission.alias === permAlias,
        ).length > 0,
      ).length > 0;
    });
  }

  allPerm(permAliasList: string[]): Observable<boolean> {
    return this.staff.map(staffData => {
      if (!staffData) {
        return false;
      }
      if (staffData.is_superuser) {
        return true;
      }
      return permAliasList.filter(permAlias =>
        staffData.permissions.filter(
          permission => permission.alias === permAlias,
        ).length > 0,
      ).length === permAliasList.length;
    });
  }

  isSuperuser(): Observable<boolean> {
    return this.staff.map((staff: Staff.Model) => staff && staff.is_superuser || false);
  }
}
