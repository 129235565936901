import {Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs/Observable';
import {BreakpointConfig, Orientation, SomeDimensions} from './website-states.enum';
import {ReplaySubject} from 'rxjs';

@Injectable()
export class WebsiteStatesService {
  viewport = Observable
    .merge(
      this.breakpoint.observe([Breakpoints.XSmall]).filter(m => m.matches)
        .map(() => <BreakpointConfig>{gteXSmall: true, gteSmall: true, gteMedium: true}),
      this.breakpoint.observe([Breakpoints.Small]).filter(m => m.matches)
        .map(() => <BreakpointConfig>{gteSmall: true, gteMedium: true}),
      this.breakpoint.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).filter(m => m.matches)
        .map(() => <BreakpointConfig>{gteMedium: true}),
    )
    .switchMap((config: BreakpointConfig) => Observable
      .merge(
        this.breakpoint
          .observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
          .filter(m => m.matches)
          .map(() => <BreakpointConfig>{
            ...config,
            gtePortraitXSmall: config.gteXSmall,
            gtePortraitSmall: config.gteSmall,
            gtePortraitMedium: config.gteMedium,
          }),
        this.breakpoint
          .observe([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape])
          .filter(m => m.matches)
          .map(() => <BreakpointConfig>{
            ...config,
            gteLandscapeXSmall: config.gteXSmall,
            gteLandscapeSmall: config.gteSmall,
            gteLandscapeMedium: config.gteMedium,
          }),
      ))
    .shareReplay(1);

  windowResize = new ReplaySubject<Window>(1);
  windowDimensions = this.windowResize
    .map(($el: Window) => <SomeDimensions>{height: $el.innerHeight, width: $el.innerWidth})
    .startWith(<SomeDimensions>{height: 0, width: 0});
  windowHeight = this.windowDimensions.map(d => d.height);
  windowWidth = this.windowDimensions.map(d => d.width - 24 * 2);
  windowDimension = Observable.combineLatest(this.windowHeight, this.windowHeight)
    .map(([w, h]) => <SomeDimensions>{width: w, height: h});
  windowOrientation = Observable.combineLatest(this.windowHeight, this.windowHeight)
    .map(([w, h]) => w > h ? Orientation.portrait : Orientation.landscape);

  constructor(private breakpoint: BreakpointObserver) {
  }
}
