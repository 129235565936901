import {isArray, isObject, isString} from 'util';
import * as _ from 'lodash';
import {HttpErrorResponse} from '@angular/common/http';

export function popThenStr(dict: { [key: string]: any }, key: string): string {
  if (dict.hasOwnProperty(key)) {
    const value = dict[key];
    delete dict[key];
    return objectToStr(value);
  } else {
    return null;
  }
}

export function objectToStr(obj: any): string {
  if (isArray(obj)) {
    return _.map(obj, item => objectToStr(item)).join('; ');
  } else if (isObject(obj)) {
    return _.map(_.toPairs(obj), ([key, value]) => `${key}: ${value}`).join('; ');
  } else if (isString(obj)) {
    return obj;
  } else {
    return JSON.stringify(obj);
  }
}

export function emptyOrComplain(dict: { [key: string]: any }) {
  if (!_.isEmpty(dict)) {
    throw JSON.stringify(dict);
  }
}


export function get400OrComplain(response: HttpErrorResponse) {
  if (response.status === 400) {
    return _.clone(response.error);
  } else {
    throw JSON.stringify(response);
  }
}

export function properServerErrorOrComplain(response: HttpErrorResponse): string {
  if (response.status === 500) {
    const keys = Object.keys(response.error);
    if (keys.length === 1 && keys[0] === 'detail') {
      return objectToStr(response.error['detail']);
    }
  }
  throw JSON.stringify(response);
}
