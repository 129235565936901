import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, Injector, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StaffSessionService} from './staff-session.service';
import {BBRestModule} from './@modules/bb-rest/bb-rest.module';
import * as Raven from 'raven-js';
import {MatNativeDateModule, MatSnackBar, MatSnackBarModule} from '@angular/material';
import {ServerEventsService} from './server-events.service';
import {StaffDBService} from './@db/staff-db.service';
import {Provider} from '@angular/core/src/di/provider';
import {WebsiteStatesService} from './website-states.service';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    if (error instanceof Error) {
      console.error(error);
    } else {
      console.error(JSON.stringify(error));
    }
    // noinspection TypeScriptUnresolvedVariable
    Raven.captureException(error.originalError || error);

    // Notify User
    const snackBar: MatSnackBar = this.injector.get(MatSnackBar);
    snackBar.open(`😨 Error: ${Raven.lastEventId()} 😱`, 'Okay', {verticalPosition: 'top'});
  }
}

let errorService: Provider = ErrorHandler;

if (location.hostname === 'console.balikbayad.ph') {
  Raven
    .config('https://3e9cdfe5bed5494eba2f09dc2059bbe9@sentry.io/1190864')
    .install();

  errorService = {provide: ErrorHandler, useClass: RavenErrorHandler};
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BBRestModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatSnackBarModule,
  ],
  providers: [
    WebsiteStatesService,
    StaffDBService,
    StaffSessionService,
    ServerEventsService,
    errorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Needed to immediately instantiate
  constructor(private server: ServerEventsService,
              private website: WebsiteStatesService) {
  }
}
