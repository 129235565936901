export interface ServerEvent {
  type: string;
  event: string;
}

export enum ServerEvents {
  instructionWarmRefresh = 'instruction.warm-refresh',
  instructionColdRefresh = 'instruction.cold-refresh',
  staffLoggedIn = 'staff.login',
  staffLoggedOut = 'staff.logout',
  botLoggedIn = 'bot.login',
  botLoggedOut = 'bot.logout',
}
