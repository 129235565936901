import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const appRoutes: Routes = [
  {path: 'login', loadChildren: './login/login.module#LoginModule'},
  {path: 'walkin-form', loadChildren: './ipad/walkin-form.module#WalkinFormModule'},
  {path: '', loadChildren: './console/console.module#ConsoleModule'},
  {path: '**', loadChildren: './@modules/not-found-page/not-found-page.module#NotFoundPageModule'},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
