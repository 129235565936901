import {Injectable} from '@angular/core';
import {WS_PREFIX} from '../const';
import {Observable} from 'rxjs/Observable';
import {ServerEvent, ServerEvents} from './server-events.enum';
import {BBRestEndpointService} from './@modules/bb-rest/bb-rest-endpoint.service';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable()
export class ServerEventsService {
  events = Observable
    .webSocket<ServerEvent>(`${WS_PREFIX}/utils/server-events`)
    .retryWhen(errorSubj => errorSubj.delayWhen(() => Observable.interval(500)))
    .shareReplay(1);
  softRefresh = this.events.filter(e => e.event === ServerEvents.instructionWarmRefresh);
  hardRefresh = this.events.filter(e => e.event === ServerEvents.instructionColdRefresh);
  justRefresh = Observable.merge(this.softRefresh, this.hardRefresh).shareReplay(1);

  foo = new ReplaySubject(1);

  isConnected: Observable<boolean> = Observable
    .create(observer => {
      const onOpen = new ReplaySubject(1);
      const onClose = new ReplaySubject(1);
      Observable.webSocket<ServerEvent>({url: `${WS_PREFIX}/utils/ping`, openObserver: onOpen, closeObserver: onClose})
        .retryWhen(errorSubj => errorSubj.delayWhen(() => Observable.interval(100)))
        .subscribe();
      Observable.merge(onOpen.map(() => true), onClose.map(() => false)).subscribe(observer);
    })
    .distinctUntilChanged()
    .shareReplay(1);
  isDisconnected = this.isConnected.map(c => !c);

  connectionDelta = <Observable<[boolean, boolean]>>this.isConnected
    .scan((oldPair, isConnected) => [oldPair[1], isConnected], [null, false]);
  justConnected: Observable<boolean> = this.connectionDelta
    .map(([past, present]) => !!(!past && present)).filter(c => c);
  justReconnected = this.justConnected.skip(1);
  justDisconnected: Observable<boolean> = this.connectionDelta
    .map(([past, present]) => (past && !present)).filter(d => d);

  constructor(private api: BBRestEndpointService) {
    this.hardRefresh.subscribe(() => location.reload(true));
  }
}
