import {AfterViewInit, Component, HostListener} from '@angular/core';
import {WebsiteStatesService} from './website-states.service';

@Component({
  selector: 'bb-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements AfterViewInit {
  constructor(private website: WebsiteStatesService) {
  }

  ngAfterViewInit() {
    this.website.windowResize.next(window);
  }

  @HostListener('window:resize')
  onResize() {
    this.website.windowResize.next(window);
  }
}
