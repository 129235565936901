export interface BreakpointConfig {
  gteXSmall: boolean;
  gteSmall: boolean;
  gteMedium: boolean;
  gtePortraitXSmall: boolean;
  gtePortraitSmall: boolean;
  gtePortraitMedium: boolean;
  gteLandscapeXSmall: boolean;
  gteLandscapeSmall: boolean;
  gteLandscapeMedium: boolean;
}

export interface SomeDimensions {
  height: number;
  width: number;
}

export enum Orientation {
  landscape = 'landscape',
  portrait = 'portrait',
}
