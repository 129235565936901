import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BBRest} from './bb-rest-params';
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import {isArray, isNull, isObject, isString} from 'util';
import {BBRestPagination} from './bb-rest-pagination.service';

@Injectable()
export class BBRestEndpointService {
  result_count;
  constructor(private http: HttpClient) {
  }

  private getAPIPrefix() {
    return '/api';
  }

  private run_request(verb: string,
                      relative_postfix: string,
                      body?: BBRest.RequestBody,
                      params?: BBRest.SemanticParams,
                      extraParams?: BBRest.ExtraParams) {
    const finalParams: BBRest.EndpointParams = {};
    let finalUrl: string = this.getAPIPrefix() + '/' + relative_postfix;
    const finalOptions: {
      params: BBRest.EndpointParams,
      body?: BBRest.RequestBody,
    } = {params: finalParams};

    if (!params) {
      params = {};
    }

    if (params.nested) {
      finalUrl = this.getAPIPrefix() + '/' + params.nested.join('/') + '/' + relative_postfix;
    }

    if (body) {
      finalOptions['body'] = body;
    }

    if (params.query) {
      let queryStr = params.query;
      if (!isString(queryStr)) {
        queryStr = JSON.stringify(queryStr, (key, value) => {
          if (!isNull(value)) {
            return value;
          }
        });
      }
      finalParams.query = queryStr;
    }

    if (params.count) {
      finalParams.count = params.count ? '1' : '0';
    } else if (params.exists) {
      finalParams.exists = params.exists ? '1' : '0';
    } else if (params.first) {
      finalParams.first = params.first ? '1' : '0';
    } else if (params.last) {
      finalParams.last = params.last ? '1' : '0';
    } else if (params.get) {
      finalParams.get = params.get ? '1' : '0';
    } else if (params.pksOnly) {
      finalParams.pk = params.pksOnly ? '1' : '0';
    } else {
      // Otherwise, this is
      if (params.pageSize) {
        finalParams.limit = '' + params.pageSize;
      }
      if (params.orderBy) {
        let orderBy = params.orderBy;
        if (isObject(params) && isArray(params)) {
          orderBy = (<string[]>orderBy).join(',');
        }
        finalParams.orderBy = '' + orderBy;
      }
      if (params.pageIndex) {
        finalParams.page = '' + params.pageIndex;
      }
    }
    if (params.orderBy) {
      let orderBy = params.orderBy;
      if (isObject(params) && isArray(params)) {
        orderBy = (<string[]>orderBy).join(',');
      }
      finalParams.orderBy = '' + orderBy;
    }

    if (extraParams) {
      Object.assign(finalParams, extraParams);
    }

    return this.http.request(verb, finalUrl, finalOptions);
  }

  run_list(verb: string, resource_prefix: string,
           body?: BBRest.RequestBody,
           params?: BBRest.SemanticParams,
           extraParams?: BBRest.ExtraParams): Observable<BBRest.ResponseBody> {
    return this.run_request(verb, resource_prefix, body, params, extraParams);
  }

  run_detail(verb: string, resource_prefix: string, pk: any,
             body?: BBRest.RequestBody,
             params?: BBRest.SemanticParams,
             extraParams?: BBRest.ExtraParams): Observable<BBRest.ResponseBody> {
    const relative_postfix = resource_prefix + '/' + pk;
    return this.run_request(verb, relative_postfix, body, params, extraParams);
  }

  run_list_method(verb: string, resource_prefix: string, method: string,
                  body?: BBRest.RequestBody,
                  params?: BBRest.SemanticParams,
                  extraParams?: BBRest.ExtraParams): Observable<BBRest.ResponseBody> {
    const relative_postfix = resource_prefix + '/' + method;
    return this.run_request(verb, relative_postfix, body, params, extraParams);
  }

  run_detail_method(verb: string, resource_prefix: string, pk: any, method: string,
                    body?: BBRest.RequestBody,
                    params?: BBRest.SemanticParams,
                    extraParams?: BBRest.ExtraParams): Observable<BBRest.ResponseBody> {
    const relative_postfix = resource_prefix + '/' + pk + '/' + method;
    return this.run_request(verb, relative_postfix, body, params, extraParams);
  }

  getListResult<M>(resource_prefix: string,
                   query: any = {},
                   page: number = 1,
                   ordering: string = null,
                   limit: number = 100,
                   extra_params?: BBRest.ExtraParams): Observable<BBRest.RecordList<M>> {
    const params: BBRest.SemanticParams = {
      pageIndex: page,
      orderBy: ordering,
      pageSize: limit,
    };
    if (query) {
      params.query = JSON.stringify(query);
    }

    return this.run_list('GET', resource_prefix, null, params, extra_params);
  }

  getAllRecords<M>(resource_prefix: string,
                   query: any = {},
                   ordering: string = null,
                   extra_params?: { [name: string]: any },
                   recordsPerRequest: number = 1000): Observable<M> {
    return Observable.create(async observer => {
      let pageNumber = 1;
      while (true) {
        const results = await this.getListResult<M>(resource_prefix, query, pageNumber, ordering, recordsPerRequest, extra_params)
          .toPromise();
        for (const record of results.results) {
          observer.next(record);
        }
        if (!results.next) {
          break;
        }
        pageNumber++;
      }
      observer.complete();
    });
  }

  getAllPks(resource_prefix: string,
            query: any = {},
            ordering: string = null,
            extra_params?: { [name: string]: any },
            recordsPerRequest: number = 1000): Observable<number> {
    return this.getAllRecords<number>(resource_prefix, query, ordering, {...extra_params, pksOnly: true}, recordsPerRequest);
  }

  getLimitedRecords<M>(resource_prefix: string,
                       query: any = {},
                       ordering: string = null,
                       extra_params?: { [name: string]: any },
                       resultsLimit = 1000): Observable<M> {
    return Observable.create(async observer => {
      const pageLimit = Math.min(resultsLimit, 1000);
      let pageNumber = 1;
      let resultsCount = 0;

      while (true) {
        const results = await this.getListResult<M>(resource_prefix, query, pageNumber, ordering, pageLimit, extra_params).toPromise();
        this.result_count = results.count;
        for (const record of results.results) {
          observer.next(record);
          resultsCount++;
          if (resultsCount >= resultsLimit) {
            break;
          }
        }
        if (!results.next) {
          break;
        }
        if (resultsCount >= resultsLimit) {
          break;
        }
        pageNumber++;
      }
      observer.complete();
    });
  }

  getPagination<M>(resource_prefix?: string, pageLimit?: number): BBRestPagination<M> {
    const pagination = new BBRestPagination<M>(this);
    if (resource_prefix) {
      pagination.prefix = resource_prefix;
    }
    if (pageLimit) {
      pagination.pageLimit = pageLimit;
    }
    return pagination;
  }
}
