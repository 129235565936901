import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BBRestEndpointService} from './bb-rest-endpoint.service';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
  ],
  providers: [
    BBRestEndpointService,
  ],
})
export class BBRestModule {
}
