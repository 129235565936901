import {BBRestEndpointService} from './bb-rest-endpoint.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';
import {PaginationEvents, ParamValue, QueryExpression, SetPageOptions} from './bb-rest-pagination.interface';
import * as _ from 'lodash';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/startWith';


export class BBRestPagination<M> {
  results = new BehaviorSubject<M[]>([]);
  private refreshSubject = new Subject<void>();
  private refreshDebounce = this.refreshSubject.debounceTime(200);
  events = new Subject<PaginationEvents>();
  busy = new BehaviorSubject(false);

  _query: QueryExpression;
  _extraParams: { [key: string]: ParamValue };
  _page = 0;
  _pageLimit = 100;
  resultCount: number = null;
  _orderBy: string;
  _prefix: string;
  // TODO use after bb-rest ASC and DESC is improved
  desending: boolean;
  protected validTaskID: string;

  constructor(public readonly manager: BBRestEndpointService) {
    this.refreshDebounce.subscribe(() => this.getRecords());
  }

  get prefix(): string {
    return this._prefix;
  }

  set prefix(prefix: string) {
    const oldPrefix = this._prefix;
    this._prefix = prefix;

    if (oldPrefix !== prefix) {
      this._page = 0;  // Changing the prefix should go back to page=0
      this.refreshSubject.next();
    }
  }

  get query(): QueryExpression {
    return this._query;
  }

  set query(query: QueryExpression) {
    const oldQuery = this._query;
    this._query = query;
    if (!_.isEqual(oldQuery, query)) {
      this._page = 0;  // Changing the query should go back to page=0
      this.refreshSubject.next();
    }
  }

  get extraParams(): { [key: string]: ParamValue } {
    return this._extraParams;
  }

  set extraParams(extraParams: { [key: string]: ParamValue }) {
    const oldExtraParams = this._extraParams;
    this._extraParams = extraParams;
    if (!_.isEqual(oldExtraParams, extraParams)) {
      this._page = 0;  // Changing the query should go back to page=0
      this.refreshSubject.next();
    }
  }

  getExtraParam(key: string): ParamValue {
    return this._extraParams[key];
  }

  setExtraParams(params: { [key: string]: ParamValue }) {
    this.extraParams = params;
  }

  updateExtraParams(params: { [key: string]: ParamValue }) {
    const oldExtraParams = this._extraParams;
    this._extraParams = _.assign({}, this._extraParams, params);
    if (!_.isEqual(oldExtraParams, this._extraParams)) {
      this._page = 0;  // Changing the query should go back to page=0
      this.refreshSubject.next();
    }
  }

  deleteExtraParam(...keys: string[]) {
    for (const key in keys) {
      if (this._extraParams.hasOwnProperty(key)) {
        delete this._extraParams[key];
      }
    }
    this.refreshSubject.next();
  }

  get page(): number {
    return this._page;
  }

  set page(page: number) {
    const oldPage = this._page;
    this._page = page;
    if (oldPage !== page) {
      this.refreshSubject.next();
    }
  }

  get pageLimit(): number {
    return this._pageLimit;
  }

  set pageLimit(pageLimit: number) {
    const oldPageLimit = this._pageLimit;
    this._pageLimit = pageLimit;
    if (oldPageLimit !== pageLimit) {
      this.refreshSubject.next();
    }
  }

  get pageCount(): number {
    if (this.resultCount) {
      return Math.ceil(this.resultCount / this.pageLimit);
    } else {
      return 0;
    }
  }

  get orderBy(): string {
    return this._orderBy;
  }

  set orderBy(orderBy: string) {
    const oldOrderBy = orderBy;
    this._orderBy = orderBy;
    if (oldOrderBy !== orderBy) {
      this.refreshSubject.next();
    }
  }

  async getRecords(): Promise<void> {
    // If prefix is not provided, don't bother
    if (!this.prefix) {
      return;
    }

    const task_id = this.validTaskID = _.uniqueId();
    this.events.next(PaginationEvents.refreshStart);
    this.busy.next(true);

    // If another refresh supersedes this refresh, abort
    if (task_id !== this.validTaskID) {
      this.events.next(PaginationEvents.refreshCancelled);
      return;
    }

    const pageResults = await this.manager
      .getListResult<M>(this.prefix, this.query, this.page + 1, this.orderBy, this.pageLimit, this.extraParams)
      .toPromise();

    // If by this time we are superseded, do not emit results
    if (task_id !== this.validTaskID) {
      this.events.next(PaginationEvents.refreshCancelled);
      return;
    }

    // Conclude
    this.resultCount = pageResults.count;
    this.results.next(pageResults.results);
    this.events.next(PaginationEvents.refreshEnd);
    this.busy.next(false);
  }

  refresh() {
    this.refreshSubject.next();
  }

  setPage(options: SetPageOptions) {
    this.page = options.page || options.pageIndex;
    this.pageLimit = options.pageLimit || options.pageSize;
  }
}
